import React, { useEffect, useState } from "react";
import { Button, Modal, ProgressBar } from "react-bootstrap";
import SwimmerSortedTimeTable from "./SwimmerSortedTimeTable";
import { Table } from "react-bootstrap";
import {
  EventGender as EventGenderWASM,
  MainModule,
  RelayCategory as RelayCategoryWASM,
  RelayHelperController,
  RelayId,
  Style as StyleWASM,
  SwimmerEntryWrapper,
  TimesPool,
  VectorRelaySummaryController_SwimmerEntryWrapper,
} from "../TeamsRelaysHelper";

type RelayProps = {
  teamName: string;
  sessionId: number;
  year: number;
  relayHelperCore: MainModule;
  relayHelperController: RelayHelperController;
  relayId: RelayId;
  removeRelay: (relayId: RelayId) => void;
  bgClass: string;
};

const getWasmGenderRepr = (
  gender: EventGenderWASM,
  relayHelperCore: MainModule
) => {
  switch (gender) {
    case relayHelperCore.EventGender.MALE:
      return "Masculino";
    case relayHelperCore.EventGender.FEMALE:
      return "Femenino";
    case relayHelperCore.EventGender.MIXED:
      return "Mixto";
  }
};
const getWasmStyleRepr = (
  style: StyleWASM,
  relayHelperCore: MainModule
): string => {
  switch (style) {
    case relayHelperCore.Style.BUTTERFLY:
      return "mariposa";
    case relayHelperCore.Style.BACK:
      return "espalda";
    case relayHelperCore.Style.BREAST:
      return "braza";
    case relayHelperCore.Style.FREE:
      return "crol";
    case relayHelperCore.Style.MEDLEY:
      return "estilos";
  }
  return "error";
};
const getWasmRelayCategoryRepr = (
  category: RelayCategoryWASM,
  relayHelperCore: MainModule
) => {
  switch (category) {
    case relayHelperCore.RelayCategory._80:
      return "+80";
    case relayHelperCore.RelayCategory._100:
      return "+100";
    case relayHelperCore.RelayCategory._120:
      return "+120";
    case relayHelperCore.RelayCategory._160:
      return "+160";
    case relayHelperCore.RelayCategory._200:
      return "+200";
    case relayHelperCore.RelayCategory._240:
      return "+240";
    case relayHelperCore.RelayCategory._280:
      return "+280";
    case relayHelperCore.RelayCategory._320:
      return "+320";
    case relayHelperCore.RelayCategory._360:
      return "+360";
  }
};
const getWasmPoolRepr = (pool: TimesPool, relayHelperCore: MainModule) => {
  switch (pool) {
    case relayHelperCore.TimesPool._25:
      return "25";
    case relayHelperCore.TimesPool._50:
      return "50";
  }
};

export const getRelayHeader = (
  relayId: RelayId,
  relayHelperCore: MainModule
) => {
  return (
    "4x" +
    relayId.event.distance.toString() +
    " " +
    getWasmStyleRepr(relayId.event.style, relayHelperCore) +
    " " +
    getWasmGenderRepr(relayId.event.gender, relayHelperCore) +
    " " +
    getWasmRelayCategoryRepr(relayId.category, relayHelperCore)
  );
};

interface MyRelaySummary {
  id: RelayId;
  swimmers: SwimmerEntryWrapper[];
  totalTime: number;
  sumAge: number;
}

const getSwimmersFromVector = (
  swimmers: VectorRelaySummaryController_SwimmerEntryWrapper
) => {
  const entries: SwimmerEntryWrapper[] = [];
  for (let i = 0; i < swimmers.size(); i++) {
    const entry = swimmers.get(i);
    if (entry) {
      entries.push(entry);
    }
  }
  return entries;
};

const Relay = ({
  teamName,
  sessionId,
  year,
  relayHelperCore,
  relayHelperController,
  relayId,
  removeRelay,
  bgClass,
}: RelayProps) => {
  const [showLoading, setShowLoading] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);

  const getSessionOperationController = () => {
    return relayHelperController.getSessionOperationController(
      teamName,
      sessionId.toString(),
      year
    );
  };

  const getRelaySetSwimmerController = (position: number) => {
    return relayHelperController.getRelaySetSwimmerController(
      teamName,
      sessionId.toString(),
      relayId,
      year,
      position
    );
  };

  const getRelaySummary = (): MyRelaySummary => {
    const relaySummaryWasm = getSessionOperationController()
      .getRelaySummaryController(relayId)
      .getSummary(year);
    return {
      id: relaySummaryWasm.id,
      swimmers: getSwimmersFromVector(relaySummaryWasm.swimmers),
      totalTime: relaySummaryWasm.totalTime,
      sumAge: relaySummaryWasm.sumAge,
    };
  };

  useEffect(() => {
    const updateRelay = () => {
      setRelaySummary(getRelaySummary());
    };

    const relayChangeObserver = relayHelperController.createRelayObserver(
      teamName,
      sessionId.toString(),
      relayId,
      updateRelay
    );
    relayChangeObserver.registerObserver();

    return () => {
      relayChangeObserver.unregisterObserver();
    };
  }, []);

  const [relaySummary, setRelaySummary] = useState<MyRelaySummary>(
    getRelaySummary()
  );

  const [selectedRow, setSelectedRow] = useState(0);
  const [setSwimmerController, setSelectSwimmerController] = useState(
    getRelaySetSwimmerController(selectedRow)
  );
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const handleRowOnClick = (rowIndex: number) => {
    setSelectedRow(rowIndex);
    setSelectSwimmerController(getRelaySetSwimmerController(rowIndex));
    handleShow();
  };

  const handleRelayAutoFill = async () => {
    relayHelperController.fillRelay(
      teamName,
      sessionId.toString(),
      relayId,
      year
    );
    setLoadingProgress(0);
  };

  const clearRelay = () => {
    relayHelperController.cleanRelay(teamName, sessionId.toString(), relayId);
  };

  const timeToString = (t: number) => {
    const minutes = Math.floor(t / 60);
    t -= minutes * 60;
    return minutes.toFixed(0) + ":" + t.toFixed(2).padStart(5, "0");
  };

  return (
    <>
      <div className={bgClass}>
        <h3>{getRelayHeader(relayId, relayHelperCore)}</h3>
        <Table striped>
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Año de nacimiento</th>
              <th scope="col">Estilo</th>
              <th scope="col">Time</th>
              <th scope="col">Edad</th>
            </tr>
          </thead>
          <tbody>
            {relaySummary.swimmers.map((d, index) => (
              <tr
                key={index}
                onClick={() => {
                  handleRowOnClick(index);
                }}
              >
                <th>
                  {d.available()
                    ? d.id().name.toString() + " " + d.id().surname.toString()
                    : ""}
                </th>
                <td>{d.available() ? d.id().bornYear : ""}</td>
                <td>{getWasmStyleRepr(d.style(), relayHelperCore)}</td>
                <td>{d.available() ? timeToString(d.time()) : ""}</td>
                <td>{d.available() ? d.age() : ""}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className={"row"}>
          <div className={"col-auto"}>
            <Button
              variant="secondary"
              onClick={() => {
                setShowLoading(true);
                handleRelayAutoFill()
                  .then(() => {
                    setShowLoading(false);
                  })
                  .catch(() => {
                    setShowLoading(false);
                  });
              }}
            >
              Auto-rellenar
            </Button>
          </div>
          <div className={"col-auto mr-auto"}>
            <div>Tiempo total: {timeToString(relaySummary.totalTime)}</div>
            <div>Suma de años: {relaySummary.sumAge}</div>
          </div>
          <div className={"col-auto"}>
            <Button variant="secondary" onClick={clearRelay}>
              Limpiar
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                removeRelay(relayId);
              }}
            >
              Borrar
            </Button>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Elije nadador</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SwimmerSortedTimeTable
            header={getRelayHeader(relayId, relayHelperCore)}
            handleClose={handleClose}
            controller={setSwimmerController}
            year={year}
            getWasmStyleRepr={(style: StyleWASM): string => {
              return getWasmStyleRepr(style, relayHelperCore);
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              getRelaySetSwimmerController(selectedRow).removeSwimmer();
              handleClose();
            }}
          >
            Borrar
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showLoading}>
        <Modal.Body>
          <ProgressBar
            now={loadingProgress}
            label={`${loadingProgress.toFixed(1)}%`}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Relay;
